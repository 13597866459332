const app = new window.Vue({
    el: '.login',
    data() {
        return {
            forgotPassword: false,
        }
    },
    methods: {
        showForgotPassword() {
            this.forgotPassword = true
            const errorDiv = document.getElementById('error-messages')
            if(errorDiv){
                errorDiv.style.display = 'none'
            }
        },
    }
});
